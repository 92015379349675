import React from "react";

const medalFilenames = [
  "first.png",
  "second.png",
  "third.png",
  "fourth.png",
  "fifth.png",
  "six.png",
  "seventh.png",
  "eight.png",
  "nine.png",
  "ten.png",
];

export function getMedalImage(wins) {
  if (wins <= 0) return null;

  if (wins <= 30) {
    const subRange = 30 / 10; // 3 wins per image
    const index = Math.min(Math.ceil(wins / subRange), 10);
    return `/Bronze-medal/${medalFilenames[index - 1]}`;
  }

  if (wins <= 100) {
    const offset = wins - 30;
    const subRange = 70 / 10; // 7 wins per image
    const index = Math.min(Math.ceil(offset / subRange), 10);
    return `/Silver-medal/${medalFilenames[index - 1]}`;
  }

  const offset = wins - 100;
  const subRange = (300 - 100) / 10; // 20 wins per image
  const index = Math.min(Math.ceil(offset / subRange), 10);
  return `/Golden-medal/${medalFilenames[index - 1]}`;
}

function getMedalTier(wins) {
  if (wins <= 30) return "bronze";
  if (wins <= 100) return "silver";
  return "gold";
}

const Medal = ({ wins }) => {
  const medalSrc = getMedalImage(wins);
  if (!medalSrc) return null;
  
  const tier = getMedalTier(wins);
  let bgClass = "";
  if (tier === "bronze") {
    bgClass = "bg-gradient-to-r from-orange-700 via-orange-500 to-orange-300";
  } else if (tier === "silver") {
    bgClass = "bg-gradient-to-r from-gray-400 via-gray-300 to-gray-100";
  } else if (tier === "gold") {
    bgClass = "bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-300";
  }

  return (
    <div className={`flex items-center rounded-md py-1 px-3 ${bgClass} shadow-xl`}>
      <img src={medalSrc} alt="Medal" className="h-10 w-auto" />
      <p className="ml-2 text-lg text-white font-semibold">
        {wins} win{wins !== 1 && "s"}!
      </p>
    </div>
  );
};

export default Medal;
