import React, { useState } from "react";

const Updates = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const updates = [
    {
      version: "1.0",
      date: "2025-02-23",
      changes: [
        "Players updated"
      ],
    }
  ];

  return (
    <>
      {/* Updates Icon */}
      <button
        onClick={togglePopup}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex items-center justify-center h-full w-full"
      >
        <img
          src="/refresh.png"
          alt="Updates"
          className={`h-8 w-8 cursor-pointer ${
            isHovered ? "animate-spinOnce" : "animate-spinReverse"
          }`}
        />
      </button>

      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
          <div className="bg-gray-900 rounded-lg shadow-lg p-6 max-w-lg w-full text-white relative overflow-auto max-h-[80vh] m-4">
            {/* Header */}
            <h2 className="text-3xl font-bold mb-4">Updates</h2>

            {/* Updates List */}
            {updates.map((update, index) => (
              <div key={index} className="mb-6">
                <h3 className="text-xl font-semibold mb-2">
                  Version {update.version} - {update.date}
                </h3>
                <ul className="list-disc list-inside space-y-2">
                  {update.changes.map((change, idx) => (
                    <li key={idx}>{change}</li>
                  ))}
                </ul>
              </div>
            ))}

            {/* Close Button */}
            <button
              onClick={togglePopup}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
            <button
              onClick={togglePopup}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Updates;