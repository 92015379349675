import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import Confetti from "react-confetti";
import Header from "../../components/GameBoard/Header";
import About from "../../components/Nba/About";
import HowToPlay from "../../components/Nba/HowToPlay";
import Updates from "../../components/Nba/Updates";
import Medal from "../../components/GameBoard/Medal";

const getTeamLogoFilename = (teamName) => {
  return `/nba/nba-${teamName.toLowerCase().replace(/ /g, "-")}-logo.png`;
};

const getConferenceLogoFilename = (conference) => {
  if (!conference) return "";
  if (conference.toLowerCase() === "eastern") {
    return "/nba/nba-eastern-conference-logo.png";
  } else if (conference.toLowerCase() === "western") {
    return "/nba/nba-western-conference-logo.png";
  }
  return "";
};

const getConferenceForTeam = (team) => {
  const easternTeams = [
    "Atlanta Hawks",
    "Boston Celtics",
    "Brooklyn Nets",
    "Charlotte Hornets",
    "Chicago Bulls",
    "Cleveland Cavaliers",
    "Detroit Pistons",
    "Indiana Pacers",
    "Miami Heat",
    "Milwaukee Bucks",
    "New York Knicks",
    "Orlando Magic",
    "Philadelphia 76ers",
    "Toronto Raptors",
    "Washington Wizards",
  ];

  const westernTeams = [
    "Dallas Mavericks",
    "Denver Nuggets",
    "Golden State Warriors",
    "Houston Rockets",
    "Los Angeles Clippers",
    "Los Angeles Lakers",
    "Memphis Grizzlies",
    "Minnesota Timberwolves",
    "New Orleans Pelicans",
    "Oklahoma City Thunder",
    "Phoenix Suns",
    "Portland Trail Blazers",
    "Sacramento Kings",
    "San Antonio Spurs",
    "Utah Jazz",
  ];

  if (easternTeams.includes(team)) return "Eastern";
  if (westernTeams.includes(team)) return "Western";
  return "";
};

const getBackgroundColor = (value, dailyValue, type) => {
  if (value === dailyValue) return "bg-green-500 text-white";

  if (type === "number") {
    const numValue = parseFloat(value);
    const numDaily = parseFloat(dailyValue);
    if (
      !isNaN(numValue) &&
      !isNaN(numDaily) &&
      Math.abs(numValue - numDaily) <= 5
    ) {
      return "bg-yellow-500 text-black";
    }
  }
  if (type === "height") {
    // NBA heights may be closer in difference; using a tolerance of 2 inches here.
    const parseHeight = (str) => {
      if (!str) return NaN;
      const parts = str.split("-");
      if (parts.length !== 2) return NaN;
      const feet = parseInt(parts[0], 10);
      const inches = parseInt(parts[1], 10);
      return feet * 12 + inches;
    };
    const hValue = parseHeight(value);
    const hDaily = parseHeight(dailyValue);
    if (!isNaN(hValue) && !isNaN(hDaily) && Math.abs(hValue - hDaily) <= 2) {
      return "bg-yellow-500 text-black";
    }
  }
  return "bg-red-500 text-white";
};

// Calculate time left until midnight (UTC)
const calculateTimeLeft = () => {
  const now = DateTime.utc();
  const midnightUTC = now.endOf("day");
  const difference = midnightUTC.diff(now, ["hours", "minutes", "seconds"]);
  return {
    hours: difference.hours,
    minutes: difference.minutes,
    seconds: Math.floor(difference.seconds),
  };
};

const ClassicGameNba = () => {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [guess, setGuess] = useState("");
  const [dailyPlayer, setDailyPlayer] = useState(null);
  const [gameWon, setGameWon] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [progress, setProgress] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [arrowSrc, setArrowSrc] = useState("/arrow-right.png");
  const cardRef = useRef(null);
  const [userGuessRank, setUserGuessRank] = useState(null);

  // New state for total guesses
  const [totalGuesses, setTotalGuesses] = useState(0);
  const [yesterdayGuesses, setYesterdayGuesses] = useState(0);
  const [yesterdayPlayer, setYesterdayPlayer] = useState(null);

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";
  const sessionId = localStorage.getItem("session_id");

  // Reset NBA game state for a new day (using nba_ prefix)
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const lastPlayedDate = localStorage.getItem("nba_lastPlayedDate");

    if (lastPlayedDate !== today) {
      console.log("New day detected - resetting NBA game state!");
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("nba_gameWon") || key.startsWith("nba_guesses")) {
          localStorage.removeItem(key);
        }
      });
      localStorage.setItem("nba_lastPlayedDate", today);
      localStorage.removeItem("nba_gameWon");
      setGuesses([]);
      setGameWon(false);

      if (navigator.userAgent.toLowerCase().includes("mobile")) {
        window.location.reload();
      }
    }
  }, []);

  // Fetch NBA players data
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/nba/players`);
        if (!response.ok) throw new Error("Failed to fetch players");
        const data = await response.json();
        // Expected NBA player fields: id, jersey, name, position, ht, age, yos, team, draftTeam, draftYear, nationality
        setPlayers(data);
      } catch (error) {
        console.error("Error fetching NBA players:", error.message);
      }
    };
    fetchPlayers();
  }, [API_BASE_URL]);

  // Fetch the daily NBA player
  useEffect(() => {
    const fetchDailyPlayer = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/nba/daily-player`, {
          headers: { "x-session-id": sessionId },
        });
        if (!response.ok) throw new Error("Failed to fetch daily player");
        const data = await response.json();
        if (!data) {
          console.error("No active daily player found.");
          return;
        }
        // Derive and add the conference field to the daily player
        data.conference = getConferenceForTeam(data.team);
        // The daily player now includes draftTeam, draftYear and nationality from the DB.
        setDailyPlayer(data);
        const savedGuesses =
          JSON.parse(
            localStorage.getItem(`nba_guesses_${data.id}_${sessionId}`)
          ) || [];
        setGuesses(savedGuesses);
        if (
          localStorage.getItem(`nba_gameWon_${data.id}_${sessionId}`) === "true"
        ) {
          setGameWon(true);
        }
      } catch (error) {
        console.error("Error fetching daily player:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchDailyPlayer();
  }, [API_BASE_URL, sessionId]);

  // Fetch total guesses for today's player
  useEffect(() => {
    const fetchTotalGuesses = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/nba/total-guesses`);
        if (!response.ok) throw new Error("Failed to fetch total guesses");
        const data = await response.json();
        // Assuming the API returns { totalGuesses: number }
        setTotalGuesses(data.totalGuesses);
      } catch (error) {
        console.error("Error fetching total guesses:", error.message);
      }
    };

    // Initial fetch
    fetchTotalGuesses();

    // Set up interval to refresh every 10 seconds
    const interval = setInterval(() => {
      fetchTotalGuesses();
    }, 10000);

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, [API_BASE_URL]);

  // Fetch yesterday's total guesses (and optionally player info) for a specific date
  useEffect(() => {
    const fetchYesterdayGuesses = async () => {
      try {
        const yesterday = DateTime.utc().minus({ days: 1 }).toISODate();
        const response = await fetch(
          `${API_BASE_URL}/api/nba/total-guesses-date?date=${yesterday}`
        );
        if (!response.ok)
          throw new Error("Failed to fetch yesterday's guesses");
        const data = await response.json();
        // Adjust based on the API response (here we assume it returns { date, correctGuesses })
        setYesterdayGuesses(data.correctGuesses);
        // If your API doesn't return player info, you might set yesterdayPlayer to null or remove it
        setYesterdayPlayer(null);
      } catch (error) {
        console.error("Error fetching yesterday's guesses:", error.message);
      }
    };
    fetchYesterdayGuesses();
  }, [API_BASE_URL]);

  // Update countdown timer every second
  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Handle input change in the guess field
  const handleInputChange = (e) => {
    const input = e.target.value.toLowerCase();
    setGuess(input);
    if (input) {
      const filtered = players
        .filter((player) => player.name.toLowerCase().includes(input))
        .slice(0, 20);
      setFilteredPlayers(filtered);
    } else {
      setFilteredPlayers([]);
    }
  };

  // When a suggested player is clicked, set the input to that player's name.
  const handleSelectPlayer = (player) => {
    setGuess(player.name);
    setFilteredPlayers([]);
  };

  // When submitting a guess, compare using the formatted name.
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!dailyPlayer) {
      alert("Daily player data is not available yet.");
      return;
    }
    const selectedPlayer = players.find(
      (player) => player.name.toLowerCase() === guess.toLowerCase()
    );
    if (!selectedPlayer) {
      alert("Player not found. Please select a valid player.");
      return;
    }

    // Derive the conference from the team
    const conference = getConferenceForTeam(selectedPlayer.team);

    // Build feedback object using NBA fields.
    const feedback = {
      name: selectedPlayer.name,
      team: selectedPlayer.team, // keep team name for comparison
      teamLogo: getTeamLogoFilename(selectedPlayer.team),
      jersey: selectedPlayer.jersey,
      position: selectedPlayer.position,
      ht: selectedPlayer.ht,
      age: selectedPlayer.age,
      yos: selectedPlayer.yos,
      conference, // derived conference value
      conferenceLogo: getConferenceLogoFilename(conference), // get logo based on conference
      draftTeam: selectedPlayer.draftTeam,
      draftYear: selectedPlayer.draftYear,
      nationality: selectedPlayer.nationality,
      visibleCategories: [],
    };
    setGuesses((prev) => [...prev, feedback]);

    // Update the reveal sequence to include new fields as well.
    const revealCategories = [
      "team",
      "conference",
      "jersey",
      "position",
      "ht",
      "age",
      "yos",
      "draftTeam",
      "draftYear",
      "nationality",
    ];
    revealCategories.forEach((category, index) => {
      setTimeout(() => {
        setGuesses((prev) =>
          prev.map((g, guessIndex) =>
            guessIndex === prev.length - 1
              ? { ...g, visibleCategories: [...g.visibleCategories, category] }
              : g
          )
        );
      }, index * 500);
    });

    // Check if the guessed player is the daily player.
    if (selectedPlayer.name === dailyPlayer.name) {
      setGameWon(true);
      // Call the endpoint to increment correct guesses.
      try {
        await fetch(`${API_BASE_URL}/api/nba/increment-correct-guesses`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        });
      } catch (error) {
        console.error("Error incrementing correct guesses:", error);
      }
      localStorage.setItem(
        `nba_gameWon_${dailyPlayer.id}_${sessionId}`,
        "true"
      );
      const currentWins = parseInt(
        localStorage.getItem("nba_totalWins") || "0",
        10
      );
      const currentGames = parseInt(
        localStorage.getItem("nba_totalGames") || "0",
        10
      );
      localStorage.setItem("nba_totalWins", currentWins + 1);
      localStorage.setItem("nba_totalGames", currentGames + 1);
      setConfettiRunning(true);
      setTimeout(() => setConfettiRunning(false), 7000);
      localStorage.setItem(
        `nba_guesses_${dailyPlayer.id}_${sessionId}`,
        JSON.stringify([...guesses, feedback])
      );
    }
    setGuess("");
  };

  // Save guesses to localStorage whenever they change.
  useEffect(() => {
    if (dailyPlayer) {
      localStorage.setItem(
        `nba_guesses_${dailyPlayer.id}_${sessionId}`,
        JSON.stringify(guesses)
      );
    }
  }, [guesses, dailyPlayer, sessionId]);

  // Simulate a progress bar and set a minimum loading time.
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 5;
      });
    }, 100);
    const timeout = setTimeout(() => {
      setLoading(false);
      setDataLoaded(true);
    }, 3000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  // Generate share message for social sharing.
  const generateShareMessage = () => {
    const shareUrl = "www.sportsdle.com";
    const guessCount = guesses.length;
    return `🏀🎉 I guessed today's NBA player on Sportsdle in ${guessCount} ${
      guessCount === 1 ? "guess" : "guesses"
    }! Can you do it too?\n${shareUrl}`;
  };

  // Copy share message to clipboard.
  const copyToClipboard = () => {
    const shareMessage = generateShareMessage();
    navigator.clipboard
      .writeText(shareMessage)
      .then(() => {
        alert("Copied to clipboard! Share your win with friends 🎉");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  // 1. Retrieve total wins from localStorage
  const wins = parseInt(localStorage.getItem("nba_totalWins") || "0", 10);

  if (loading) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-black text-white">
        <div
          className="radial-progress text-primary"
          style={{ "--value": progress }}
          role="progressbar"
        >
          {progress}%
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 bg-cover bg-center"
      style={{ backgroundImage: "url('/nba-background.png')" }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 pointer-events-none z-0"></div>
      <div className="relative z-10">
        <Header />
      </div>
      {confettiRunning && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}
      <div className="relative max-w-5xl mx-auto h-screen overflow-y-auto rounded-lg px-4 pt-2 pb-72 mt-5 mb-5">
        <div className="flex flex-col md:flex-row justify-center items-start gap-4 mb-4 sm:w-8/12 mx-auto">
          <div className="flex-1 w-full bg-black bg-opacity-70 rounded-lg shadow-md">
            <div className="relative text-white p-4 text-center">
              <h1 className="font-bold text-xl mb-2">
                NBA Classic Guessing Game
              </h1>
              <p className="text-base mb-2">Time left until the next player:</p>
              <span className="countdown font-mono text-2xl">
                <span style={{ "--value": timeLeft.hours }}></span>h
                <span style={{ "--value": timeLeft.minutes }}></span>m
                <span style={{ "--value": timeLeft.seconds }}></span>s
              </span>
              <div className="mt-4 flex justify-around items-start gap-4">
                <div className="text-center">
                  {guesses.length < 4 ? (
                    <p className="text-md text-gray-400">
                      Clue in {4 - guesses.length} guess(es)
                    </p>
                  ) : (
                    <p className="text-md font-bold">
                      Team: {dailyPlayer.team}
                    </p>
                  )}
                </div>
                <div className="text-center">
                  {guesses.length < 6 ? (
                    <p className="text-md text-gray-400">
                      Clue in {6 - guesses.length} guess(es)
                    </p>
                  ) : (
                    <p className="text-md font-bold">
                      Jersey: {dailyPlayer.jersey}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Input and Filtered Players */}
        <div className="w-full sm:w-5/6 mb-4 mx-auto flex flex-col space-y-4">
          <div className="flex items-center justify-center gap-4">
            {/* Buttons Container */}
            <div className="flex items-center justify-between bg-neutral bg-opacity-95 rounded-md shadow-md px-3 py-2 sm:w-3/12 w-6/12">
              <div className="tooltip cursor-pointer" data-tip="How to play">
                <HowToPlay />
              </div>
              <div className="h-6 w-[1.5px] bg-gray-500 mx-1"></div>
              <div className="tooltip cursor-pointer" data-tip="Updates">
                <Updates />
              </div>
              <div className="h-6 w-[1.5px] bg-gray-500 mx-1"></div>
              <div className="tooltip cursor-pointer" data-tip="About">
                <About />
              </div>
            </div>

            {/* Medal Component */}
            <Medal wins={wins} />
          </div>

          <div className="relative w-full sm:w-5/6 mx-auto">
            <form onSubmit={handleSubmit} className="relative">
              <input
                type="text"
                value={guess}
                onChange={handleInputChange}
                placeholder={
                  gameWon
                    ? "You found the player! Wait for the next one."
                    : "Start typing a player's name"
                }
                className={`w-full h-12 p-3 pr-12 rounded-lg text-lg ${
                  gameWon
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-white text-black"
                }`}
                disabled={gameWon}
              />
              {!gameWon && (
                <button
                  type="submit"
                  onMouseEnter={() => setArrowSrc("/arrow-right-blue.png")}
                  onMouseLeave={() => setArrowSrc("/arrow-right.png")}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <img src={arrowSrc} alt="Submit" className="w-8 h-8" />
                </button>
              )}
            </form>
            {filteredPlayers.length > 0 && (
              <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-lg mt-2 max-h-40 overflow-y-auto z-20">
                {filteredPlayers.map((player) => (
                  <li
                    key={player.id}
                    onClick={() => handleSelectPlayer(player)}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center text-black"
                  >
                    <span>{player.name}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="text-center">
          <h3 className="text-sm font-bold">
            <span className="text-yellow-400">{totalGuesses}</span> people
            already found today's player!
          </h3>
        </div>

        {/* Guesses Comparison Grid */}
        <div className="overflow-x-auto">
          <div className="space-y-2 w-max mx-auto font-bold">
            {/* Grid Header */}
            <div className="grid grid-cols-11 gap-2 text-white text-center p-1 font-bold text-md whitespace-nowrap">
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Player
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Team
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Conference
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Jersey
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Position
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Height
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Age
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Years
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Draft Team
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Draft Year
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Nationality
              </div>
            </div>

            {/* Grid Rows */}
            {[...guesses].reverse().map((g, rowIndex) => (
              <div
                key={rowIndex}
                className="grid grid-cols-11 gap-2 bg-gray-900 bg-opacity-60 p-2 rounded-lg text-md whitespace-nowrap"
              >
                <div className="flex justify-center items-center text-center w-20 h-20 font-bold whitespace-normal break-words">
                  {g.name}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("team")
                      ? getBackgroundColor(g.team, dailyPlayer.team, "string")
                      : "bg-gray-700"
                  }`}
                >
                  {g.visibleCategories.includes("team") ? (
                    <img
                      src={g.teamLogo}
                      alt={g.team}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    "?"
                  )}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("conference")
                      ? getBackgroundColor(
                          g.conference,
                          dailyPlayer.conference,
                          "string"
                        )
                      : "bg-gray-700"
                  }`}
                >
                  {g.visibleCategories.includes("conference") ? (
                    <img
                      src={g.conferenceLogo}
                      alt={g.conference}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    "?"
                  )}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("jersey")
                      ? getBackgroundColor(
                          g.jersey,
                          dailyPlayer.jersey,
                          "number"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("jersey") ? g.jersey : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("position")
                      ? getBackgroundColor(
                          g.position,
                          dailyPlayer.position,
                          "string"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("position") ? g.position : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("ht")
                      ? getBackgroundColor(g.ht, dailyPlayer.ht, "height")
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("ht") ? g.ht : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("age")
                      ? getBackgroundColor(g.age, dailyPlayer.age, "number")
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("age") ? g.age : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("yos")
                      ? getBackgroundColor(g.yos, dailyPlayer.yos, "number")
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("yos") ? g.yos : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg whitespace-normal break-words ${
                    g.visibleCategories.includes("draftTeam")
                      ? getBackgroundColor(
                          g.draftTeam,
                          dailyPlayer.draftTeam,
                          "string"
                        )
                      : "bg-gray-700"
                  }`}
                >
                  {g.visibleCategories.includes("draftTeam")
                    ? g.draftTeam
                    : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg whitespace-normal break-words ${
                    g.visibleCategories.includes("draftYear")
                      ? getBackgroundColor(
                          g.draftYear,
                          dailyPlayer.draftYear,
                          "string"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("draftYear")
                    ? g.draftYear
                    : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg whitespace-normal break-words ${
                    g.visibleCategories.includes("nationality")
                      ? getBackgroundColor(
                          g.nationality,
                          dailyPlayer.nationality,
                          "string"
                        )
                      : "bg-gray-700"
                  }`}
                >
                  {g.visibleCategories.includes("nationality")
                    ? g.nationality
                    : "?"}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="block md:hidden text-center text-white mt-2">
          <p className="text-sm">
            Swipe or scroll to the right to view more details!
          </p>
        </div>
        <div className="text-center my-10">
          <h3 className="text-sm font-bold">
            <span className="text-yellow-400">{yesterdayGuesses}</span> people
            found yesterday's player{" "}
            {yesterdayPlayer?.name && (
              <span className="text-yellow-500">({yesterdayPlayer.name})</span>
            )}
            !
          </h3>
        </div>
        {gameWon && (
          <div
            ref={cardRef}
            className="text-white text-center p-5 rounded-lg mt-8 max-w-md mx-auto"
            style={{
              background: "linear-gradient(135deg, #1b5e20, #388e3c)",
              border: "2px solid #4caf50",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h2 className="text-3xl font-bold mb-4">Congratulations!</h2>
            <div className="flex flex-col items-center">
              <p className="text-lg">You found</p>
              <div className="flex items-center mt-2">
                <span className="text-2xl font-bold ml-3">
                  {dailyPlayer?.name}
                </span>
              </div>
            </div>
            <p className="text-lg mt-2">
              This player is for the day:{" "}
              <strong>
                {DateTime.fromISO(dailyPlayer?.date, { zone: "utc" })
                  .setZone("America/New_York")
                  .toFormat("yyyy-MM-dd")}
              </strong>
            </p>
            <div className="mt-4 flex flex-col items-center w-full space-y-2">
              <button
                onClick={copyToClipboard}
                className="flex items-center justify-center w-full bg-gray-600 text-white font-semibold py-3 rounded-lg hover:bg-gray-700 transition"
              >
                <img
                  src="/logos/clipboard.png"
                  alt="Copy"
                  className="w-5 h-5 mr-2"
                />
                Copy Result
              </button>
              <a
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  generateShareMessage()
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full bg-[#1DA1F2] text-white font-semibold py-3 rounded-lg hover:bg-[#0d8bcd] transition"
              >
                <img
                  src="/logos/twitter.png"
                  alt="Twitter"
                  className="w-5 h-5 mr-2"
                />
                Share on Twitter
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  "https://yournflapp.com"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full bg-[#1877F2] text-white font-semibold py-3 rounded-lg hover:bg-[#145dbf] transition"
              >
                <img
                  src="/logos/facebook.png"
                  alt="Facebook"
                  className="w-5 h-5 mr-2"
                />
                Share on Facebook
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassicGameNba;
