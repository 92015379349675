import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "lucide-react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <header className="flex items-center justify-between bg-transparent border-b border-white py-3 px-4 md:px-20">
        <Link to="/" className="flex items-center">
          <img
            src="/sportsdle_logo.png"
            alt="Sportsdle Logo"
            className="h-8 w-auto sm:h-10"
          />
        </Link>

        <div className="flex items-center space-x-4">
          <nav className="flex space-x-3">
            <Link
              to="/nhl/daily-guessing-game"
              className="flex items-center font-semibold text-lg sm:text-xl hover:text-yellow-400"
            >
              NHL
            </Link>
            <Link
              to="/nfl/daily-guessing-game"
              className="flex items-center font-semibold text-lg sm:text-xl hover:text-yellow-400"
            >
              NFL
            </Link>
            <Link
              to="/nba/daily-guessing-game"
              className="flex items-center font-semibold text-lg sm:text-xl hover:text-yellow-400 mr-5"
            >
              NBA
            </Link>
          </nav>

          <button
            className="text-white focus:outline-none"
            onClick={() => setIsMenuOpen(true)}
          >
            <Menu size={28} />
          </button>
        </div>
      </header>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-end z-50">
          <div className="bg-base-100 w-64 p-6 h-full shadow-xl">
            <button
              className="mb-4 text-black text-lg font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Close
            </button>
            <nav className="flex flex-col space-y-4">
              <Link
                to="/nhl/random-player-generator"
                className="text-black font-semibold hover:text-yellow-400"
                onClick={() => setIsMenuOpen(false)}
              >
                Random NHL Player
              </Link>
              {/* Add more links here */}
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;