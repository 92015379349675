import React, { useState } from "react";

const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* About Icon */}
      <button
        onClick={togglePopup}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex items-center justify-center h-full w-full"
      >
        <img
          src="/info.png"
          alt="About"
          className={`h-8 w-8 cursor-pointer ${
            isHovered ? "animate-spinOnce" : "animate-spinReverse"
          }`}
        />
      </button>

      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center text-left items-center z-50 overflow-auto">
          <div className="bg-gray-900 rounded-lg shadow-lg p-6 max-w-lg w-full text-white relative overflow-auto max-h-[80vh] m-4">
            {/* Header */}
            <h1 className="text-4xl font-bold text-center mb-6">About This Game</h1>

            {/* Game Description */}
            <p className="text-lg mb-6">
              Each day, guess a different **NFL player** based on various stats and clues.
            </p>

            {/* Attribution */}
            <div className="mb-6">
              <p>
                This project was created as a fan effort inspired by the NFL and its players. All player data is sourced from the{" "}
                <a
                  href="https://www.nfl.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  official NFL API
                </a>
                . This application is not endorsed or sponsored by the National Football League.
              </p>
            </div>

            {/* Inspirations */}
            <div className="mb-6">
              <p>
                Heavily inspired by these cool games:{" "}
                <a
                  href="https://www.nytimes.com/games/wordle/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  Wordle
                </a>
                ,{" "}
                <a
                  href="https://worldle.teuteuf.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  Worldle
                </a>
                , and many others.
              </p>
            </div>

            {/* Cookies and Privacy */}
            <div className="mb-6">
              <p>
                This site uses cookies to collect analytics and display advertisements. For more details, please refer to our{" "}
                <a
                  href="/privacy"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>

            {/* Feedback and Support */}
            <div className="mb-6">
              <p>
                Have a suggestion? Found an error? Just want to say hello? 😎 Send an email to{" "}
                <a
                  href="mailto:meljcontact@gmail.com"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  meljcontact@gmail.com
                </a>
                .
              </p>
              <p className="mt-4">
                If you’d like to support the game, feel free to{" "}
                
                  <a href="https://ko-fi.com/meljdev" target="_blank" className="text-yellow-400 hover:text-yellow-500 underline">buy me a coffee! ☕</a>
              </p>
            </div>

            {/* Footer */}
            <div className="text-center text-sm text-gray-500">
              <p>Created by: <a className="text-yellow-400" href="https://meljdev.ca" target="_blank">Meljdev</a></p>
            </div>

            {/* Close Button */}
            <button
              onClick={togglePopup}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
            <button
              onClick={togglePopup}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
            >
              Got It!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default About;