import React, { useState } from "react";

const HowToPlay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Question Icon */}
      <button
        onClick={togglePopup}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex items-center justify-center h-full w-full"
      >
        <img
          src="/circle-question-solid.svg"
          alt="How to Play"
          className={`h-8 w-8 cursor-pointer ${
            isHovered ? "animate-spinOnce" : "animate-spinReverse"
          }`}
        />
      </button>

      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center text-left items-center z-50 overflow-auto">
          <div className="bg-gray-900 rounded-lg shadow-lg p-6 max-w-lg w-full text-white relative overflow-auto max-h-[80vh] m-4">
            {/* Header */}
            <h2 className="text-3xl font-bold mb-4">How to Play</h2>
            <p className="mb-6">
              Welcome to the NBA Guessing Game! Here&apos;s everything you need to know:
            </p>

            {/* Instructions */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Objective</h3>
              <p className="mb-4">
                The goal is to guess the daily NBA player by analyzing categories like team, position, conference, jersey number, height, weight, age, and years in the league.
              </p>

              <h3 className="text-xl font-semibold mb-2">How to Play</h3>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Start typing a player's name in the input box.</li>
                <li>Select the player from the dropdown list.</li>
                <li>Compare their stats to the daily player.</li>
                <li>Use the colors as clues to refine your guesses.</li>
                <li>Keep guessing until you find the correct player!</li>
              </ul>

              <p className="mb-4">
                <strong>Arrows or hints</strong> (if shown) will guide you:
                <span className="block mt-2">⬆️ Higher than your guess</span>
                <span>⬇️ Lower than your guess</span>
              </p>
            </div>

            {/* Categories and Colors */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Categories Explained</h3>
              <ul className="space-y-4">
                <li>
                  <strong>Team:</strong> The player's current NBA team.
                </li>
                <li>
                  <strong>Conference:</strong> Eastern or Western, based on the team.
                </li>
                <li>
                  <strong>Position:</strong> Commonly PG (Point Guard), SG (Shooting Guard), SF (Small Forward), PF (Power Forward), or C (Center).
                </li>
                <li>
                  <strong>Jersey:</strong> The player's jersey number.
                </li>
                <li>
                  <strong>Height:</strong> The player's height in feet and inches.
                </li>
                <li>
                  <strong>Weight:</strong> The player's weight in pounds.
                </li>
                <li>
                  <strong>Age:</strong> The player's age in years.
                </li>
                <li>
                  <strong>Years:</strong> The number of seasons the player has been in the NBA.
                </li>
              </ul>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Colors &amp; What They Mean</h3>
              <ul className="space-y-4">
                <li>
                  <span className="bg-green-500 text-black px-2 py-1 rounded">Green:</span>{" "}
                  Exact match.
                </li>
                <li>
                  <span className="bg-yellow-500 text-black px-2 py-1 rounded">Yellow:</span>{" "}
                  Close match (e.g., within 5 units for numbers).
                </li>
                <li>
                  <span className="bg-red-500 text-white px-2 py-1 rounded">Red:</span>{" "}
                  Incorrect value.
                </li>
                <li>
                  <span className="bg-gray-500 text-white px-2 py-1 rounded">Gray:</span>{" "}
                  Not yet revealed or not applicable.
                </li>
              </ul>
            </div>

            {/* Example Section */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Example</h3>
              <p>
                You guess <strong>Player A</strong>, who has a jersey number of 10. If the daily player's number is 15:
              </p>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li>
                  <span className="bg-yellow-500 text-black px-2 py-1 rounded">Yellow</span>: It's
                  within 5 (close match).
                </li>
                <li>
                  <span className="bg-green-500 text-black px-2 py-1 rounded">Green</span>: If you
                  guess exactly 15.
                </li>
              </ul>
            </div>

            {/* Data Attribution */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Data Attribution</h3>
              <p className="mb-4">
                All player data is sourced from the{" "}
                <a
                  href="https://www.nfl.com/stats/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  official NFL stats site
                </a>
                . For more information, visit the{" "}
                <a
                  href="https://www.nfl.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  NFL website
                </a>
                .
              </p>
            </div>

            {/* Internal Links for SEO */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Explore More</h3>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>
                  <a
                    href="/nba/classic"
                    className="text-blue-400 hover:text-blue-500 underline"
                  >
                    NBA Classic Game Mode
                  </a>
                </li>
                <li>
                  <a
                    href="/nhl/classic"
                    className="text-blue-400 hover:text-blue-500 underline"
                  >
                    NHL Classic Guess Game
                  </a>
                </li>
                <li>
                  <a
                    href="/nfl/classic"
                    className="text-blue-400 hover:text-blue-500 underline"
                  >
                    NFL Classic Guess Game
                  </a>
                </li>
              </ul>
            </div>

            {/* Close Button */}
            <button
              onClick={togglePopup}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
            <button
              onClick={togglePopup}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
            >
              Got It!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HowToPlay;