import React, { useState, useEffect, useRef, useCallback } from "react";
import Confetti from "react-confetti";
import Header from "../../components/Nhl/Header";
import GameNavigation from "../../components/Nhl/GameNavigation";

// Debounce utility
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const UnlimitedMode = () => {
  const [players, setPlayers] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [guess, setGuess] = useState("");
  const [gameWon, setGameWon] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [arrowSrc, setArrowSrc] = useState("/arrow-right.png");

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";

  const cardRef = useRef(null);

  // Debounced function for filtering players
  const debouncedFilterPlayers = useCallback(
    debounce((input) => {
      if (input) {
        const filtered = players
          .filter((player) => player.full_name.toLowerCase().includes(input))
          .slice(0, 20); // Limit to top 20 results
        setFilteredPlayers(filtered);
      } else {
        setFilteredPlayers([]);
      }
    }, 300), // 300ms delay
    [players]
  );

  const handleInputChange = (e) => {
    const input = e.target.value.toLowerCase();
    setGuess(input);
    debouncedFilterPlayers(input);
  };

  const handleSelectPlayer = (player) => {
    setGuess(player.full_name);
    setFilteredPlayers([]);
  };

  const fetchPlayers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/players`);
      if (!response.ok) {
        throw new Error("Failed to fetch players");
      }
      const data = await response.json();
      const mappedPlayers = data.map((player) => ({
        ...player,
        conference: getConference(player.current_team_abbrev),
      }));
      setPlayers(mappedPlayers);
    } catch (error) {
      console.error("Error fetching players:", error.message);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchRandomPlayer = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/random-player`);
      if (!response.ok) {
        throw new Error("Failed to fetch random player");
      }
      const player = await response.json();

      const playerWithConference = {
        ...player,
        conference: player.current_team_abbrev
          ? getConference(player.current_team_abbrev)
          : "unknown.png", // Fallback for invalid conference
      };

      console.log(player);

      setCurrentPlayer(playerWithConference);
      setGuesses([]);
      setGameWon(false);
    } catch (error) {
      console.error("Error fetching random player:", error.message);
    }
  };

  useEffect(() => {
    console.log("Fetching random player...");
    fetchRandomPlayer();
  }, []);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }

    return age;
  };

  const getBackgroundColor = (value, dailyValue, type) => {
    // Normalize "N/A", null, or undefined to 0 for numeric comparisons
    const normalizedValue = value === "N/A" || value == null ? 0 : value;
    const normalizedDailyValue =
      dailyValue === "N/A" || dailyValue == null ? 0 : dailyValue;

    // Check for exact match
    if (normalizedValue === normalizedDailyValue) {
      return "bg-green-500 text-white"; // Exact match
    }

    const isClose = (a, b, range) => Math.abs(a - b) <= range;

    // Handle numbers
    if (type === "number") {
      if (isClose(normalizedValue, normalizedDailyValue, 5)) {
        return "bg-yellow-500 text-black"; // Close match
      } else {
        return "bg-red-500 text-white"; // Incorrect match
      }
    }

    // Handle strings
    if (type === "string") {
      return "bg-red-500 text-white"; // Incorrect string
    }

    // Default for unexpected types
    return "bg-gray-500 text-white";
  };

  const getConference = (teamAbbrev) => {
    const atlantic = ["BOS", "BUF", "DET", "FLA", "MTL", "OTT", "TBL", "TOR"];
    const metropolitan = [
      "CAR",
      "CBJ",
      "NJD",
      "NYI",
      "NYR",
      "PHI",
      "PIT",
      "WSH",
    ];
    const central = ["CHI", "COL", "DAL", "MIN", "NSH", "STL", "WPG", "UTA"];
    const pacific = ["ANA", "CGY", "EDM", "LAK", "SEA", "SJS", "VAN", "VGK"];

    if (atlantic.includes(teamAbbrev)) return "atlantic.png";
    if (metropolitan.includes(teamAbbrev)) return "metropolitan.png";
    if (central.includes(teamAbbrev)) return "central.png";
    if (pacific.includes(teamAbbrev)) return "pacific.png";
    return "unknown.png"; // Default image for unknown
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
  
    if (!currentPlayer) {
      alert("No player available. Please try again.");
      return;
    }
  
    const selectedPlayer = players.find(
      (player) => player.full_name.toLowerCase() === guess.toLowerCase()
    );
  
    if (!selectedPlayer) {
      alert("Player not found. Please select a valid player.");
      return;
    }
  
    const guessedAge = calculateAge(selectedPlayer.birth_date);
    const targetAge = calculateAge(currentPlayer.birth_date);
  
    const feedback = {
      name: selectedPlayer.full_name,
      team: selectedPlayer.current_team_full_name,
      teamLogo: selectedPlayer.current_team_logo,
      sweaterNumber: selectedPlayer.sweater_number,
      position: selectedPlayer.position,
      conference: selectedPlayer.conference,
      country: selectedPlayer.birth_country,
      age: guessedAge,
      overallPick: selectedPlayer.draft_overall_pick,
      points: selectedPlayer.career_regular_season_points,
      headshot: selectedPlayer.headshot_url,
      teamColor: getBackgroundColor(
        selectedPlayer.current_team_full_name,
        currentPlayer.current_team_full_name,
        "string"
      ),
      sweaterColor: getBackgroundColor(
        selectedPlayer.sweater_number,
        currentPlayer.sweater_number,
        "number"
      ),
      positionColor: getBackgroundColor(
        selectedPlayer.position,
        currentPlayer.position,
        "string"
      ),
      conferenceColor: getBackgroundColor(
        selectedPlayer.conference,
        currentPlayer.conference,
        "string"
      ),
      countryColor: getBackgroundColor(
        selectedPlayer.birth_country,
        currentPlayer.birth_country,
        "string"
      ),
      ageColor: getBackgroundColor(guessedAge, targetAge, "number"),
      overallPickColor: getBackgroundColor(
        selectedPlayer.draft_overall_pick,
        currentPlayer.draft_overall_pick,
        "number"
      ),
      pointsColor: getBackgroundColor(
        selectedPlayer.career_regular_season_points,
        currentPlayer.career_regular_season_points,
        "number"
      ),
    };
  
    setGuesses((prev) => [...prev, { ...feedback, visibleCategories: [] }]);
  
    // Sequentially reveal categories for this guess
    const revealCategories = [
      "headshot",
      "teamLogo",
      "conference",
      "sweaterNumber",
      "position",
      "country",
      "age",
      "overallPick",
      "careerPoints",
    ];
  
    revealCategories.forEach((category, index) => {
      setTimeout(() => {
        setGuesses((prev) =>
          prev.map((g) =>
            g.id === feedback.id
              ? {
                  ...g,
                  visibleCategories: [...g.visibleCategories, category],
                }
              : g
          )
        );
      }, index * 400); // Adjust timing between reveals
    });
  
    if (selectedPlayer.full_name === currentPlayer.full_name) {
      setGameWon(true);
      setConfettiRunning(true);
  
      // Increment the unlimited wins count
      try {
        const response = await fetch(`${API_BASE_URL}/api/increment-unlimited-wins`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error("Failed to increment unlimited wins.");
        }
  
        console.log("Unlimited wins incremented successfully.");
      } catch (error) {
        console.error("Error incrementing unlimited wins:", error);
      }
  
      // Stop confetti after 3 seconds
      setTimeout(() => {
        setConfettiRunning(false);
      }, 3000);
    }
  
    setGuess("");
  };  

  return (
    <div className="fixed inset-0 bg-cover bg-center">
      <Header />
      <GameNavigation />
      {confettiRunning && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      {gameWon && currentPlayer && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold text-green-600 mb-4">
              🎉 Congratulations! 🎉
            </h2>
            <img
              src={currentPlayer.headshot_url}
              alt={currentPlayer.full_name}
              className="w-24 h-24 rounded-full mx-auto mb-4"
            />
            <p className="text-xl font-semibold text-black">{currentPlayer.full_name}</p>
            <button
              onClick={fetchRandomPlayer} // Start a new game
              className="mt-6 bg-yellow-400 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
            >
              Play Again
            </button>
          </div>
        </div>
      )}

      <div className="relative max-w-4xl mx-auto h-screen overflow-y-auto rounded-lg px-4 pt-2 pb-72 mt-5">
        <h1 className="text-4xl font-bold mb-8 text-center text-white">
          Unlimited Mode
        </h1>

        {/* Input Section */}
        <div className="relative w-full sm:w-5/6 mx-auto">
          <form onSubmit={handleSubmit} className="relative">
            <input
              type="text"
              value={guess}
              onChange={handleInputChange}
              placeholder={
                gameWon
                  ? "You guessed it! Start a new round."
                  : "Start typing a player's name"
              }
              className={`w-full h-12 p-3 pr-12 rounded-lg text-lg ${
                gameWon
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-white text-black"
              }`}
              disabled={gameWon} // Disable input if game is won
            />
            {/* Arrow Icon */}
            {!gameWon && (
              <button
                type="submit"
                onMouseEnter={() => setArrowSrc("/arrow-right-blue.png")}
                onMouseLeave={() => setArrowSrc("/arrow-right.png")}
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
              >
                <img src={arrowSrc} alt="Submit" className="w-8 h-8" />
              </button>
            )}
          </form>

          {/* Filtered Players List */}
          {filteredPlayers.length > 0 && (
            <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-lg mt-2 max-h-40 overflow-y-auto z-20">
              {filteredPlayers.map((player) => (
                <li
                  key={player.player_id}
                  onClick={() => handleSelectPlayer(player)}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center text-black"
                >
                  <img
                    src={player.headshot_url}
                    alt={player.full_name}
                    className="w-8 h-8 rounded-full mr-3"
                  />
                  <span>{player.full_name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Categories Header */}
        <div className="overflow-x-auto mt-6">
          <div className="space-y-2 w-max mx-auto">
            <div className="grid grid-cols-9 gap-2 text-white text-center p-1 font-bold">
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Player
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Team
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Division
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Sweater
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Position
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Birth Country
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Age
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Overall Pick
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Career Points
              </div>
            </div>

            {/* Guesses */}
            {[...guesses].reverse().map((guess, rowIndex) => (
              <div
                key={rowIndex}
                className="grid grid-cols-9 gap-2 bg-gray-900 bg-opacity-60 p-2 rounded-lg"
              >
                {/* Player */}
                {guess.visibleCategories.includes("headshot") && (
                  <div className="relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg">
                    <img
                      src={guess.headshot}
                      alt={guess.name}
                      className="w-20 h-20 rounded-full"
                    />
                    <span className="absolute bottom-0 w-full text-xs font-bold text-white bg-black bg-opacity-60 px-1 rounded-b-lg">
                      {guess.name.split(" ").slice(-1)}
                    </span>
                  </div>
                )}

                {/* Team */}
                {guess.visibleCategories.includes("teamLogo") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg ${guess.teamColor}`}
                  >
                    <img
                      src={guess.teamLogo}
                      alt={`${guess.team} logo`}
                      className="w-16 h-16 rounded"
                    />
                  </div>
                )}

                {/* Conference */}
                {guess.visibleCategories.includes("conference") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.conferenceColor}`}
                  >
                    <img
                      src={`/${guess.conference}`}
                      alt={guess.conference.replace(".png", "")}
                      className="w-16 h-14"
                    />
                  </div>
                )}

                {/* Sweater */}
                {guess.visibleCategories.includes("sweaterNumber") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.sweaterColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.sweaterNumber}</span>
                    {guess.sweaterNumber > currentPlayer.sweater_number && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.sweaterNumber < currentPlayer.sweater_number && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Position */}
                {guess.visibleCategories.includes("position") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg text-2xl font-bold ${guess.positionColor}`}
                  >
                    {guess.position}
                  </div>
                )}

                {/* Country */}
                {guess.visibleCategories.includes("country") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.countryColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.country}</span>
                  </div>
                )}

                {/* Age */}
                {guess.visibleCategories.includes("age") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.ageColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.age}</span>
                    {guess.age > calculateAge(currentPlayer.birth_date) && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.age < calculateAge(currentPlayer.birth_date) && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Overall Pick */}
                {guess.visibleCategories.includes("overallPick") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.overallPickColor}`}
                  >
                    <span className="z-10 text-2xl">
                      {guess.overallPick || "N/A"}
                    </span>
                    {guess.overallPick > currentPlayer.draft_overall_pick && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.overallPick < currentPlayer.draft_overall_pick && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Career Points */}
                {guess.visibleCategories.includes("careerPoints") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.pointsColor}`}
                  >
                    <span className="z-10 text-2xl">
                      {guess.points || "N/A"}
                    </span>
                    {guess.points >
                      currentPlayer.career_regular_season_points && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.points <
                      currentPlayer.career_regular_season_points && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlimitedMode;
