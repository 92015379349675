import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import Confetti from "react-confetti";
import { getTeamLogoFilename } from "../../components/Nfl/LogoMapping";
import Header from "../../components/GameBoard/Header";
import HowToPlay from "../../components/Nfl/HowToPlay";
import About from "../../components/Nfl/About";
import Updates from "../../components/Nfl/Updates";
import Medal from "../../components/GameBoard/Medal";

// Helper to extract the age from a dob_age string (e.g. "5/21/1996 (28)" returns "28")
const extractAge = (dob_age) => {
  if (!dob_age) return "";
  const match = dob_age.match(/\((\d+)\)/);
  return match ? match[1] : dob_age;
};

// Returns the background color based on comparison:
// - Green if exact match
// - Yellow if numeric difference is within ±5
// - Red otherwise
const getBackgroundColor = (value, dailyValue, type) => {
  if (value === dailyValue) {
    return "bg-green-500 text-white";
  }
  if (type === "number") {
    const numValue = parseFloat(value);
    const numDaily = parseFloat(dailyValue);
    if (
      !isNaN(numValue) &&
      !isNaN(numDaily) &&
      Math.abs(numValue - numDaily) <= 5
    ) {
      return "bg-yellow-500 text-black";
    }
  }
  if (type === "height") {
    const hValue = parseHeight(value);
    const hDaily = parseHeight(dailyValue);
    if (!isNaN(hValue) && !isNaN(hDaily) && Math.abs(hValue - hDaily) <= 5) {
      return "bg-yellow-500 text-black";
    }
  }
  return "bg-red-500 text-white";
};

// Renders the number with an arrow positioned above if the guess is too low, or below if too high.
const renderArrowPositioned = (guessValue, actualValue, type) => {
  if (type !== "number") return <span>{guessValue}</span>;
  const g = parseFloat(guessValue);
  const a = parseFloat(actualValue);
  if (isNaN(g) || isNaN(a)) return <span>{guessValue}</span>;
  if (g < a) {
    return (
      <div className="flex flex-col items-center">
        <img src="/up-arrows.png" alt="Up arrow" className="w-4 h-4" />
        <span>{guessValue}</span>
      </div>
    );
  } else if (g > a) {
    return (
      <div className="flex flex-col items-center">
        <span>{guessValue}</span>
        <img src="/down-arrows.png" alt="Down arrow" className="w-4 h-4" />
      </div>
    );
  }
  return <span>{guessValue}</span>;
};

// Define which team codes are in each conference
const AFC_TEAMS = [
  "BUF",
  "MIA",
  "NE",
  "NYJ",
  "BAL",
  "CIN",
  "CLE",
  "PIT",
  "HOU",
  "IND",
  "JAC",
  "TEN",
  "DEN",
  "KC",
  "LV",
  "LAC",
];
const NFC_TEAMS = [
  "DAL",
  "NYG",
  "PHI",
  "WAS",
  "CHI",
  "DET",
  "GB",
  "MIN",
  "ATL",
  "CAR",
  "NO",
  "TB",
  "ARI",
  "LAR",
  "SF",
  "SEA",
];

// Helper to return the correct conference logo path based on team code
const getConferenceLogo = (teamCode) => {
  if (AFC_TEAMS.includes(teamCode)) {
    return "/nfl/nfl-afc-American_Football_Conference_logo.png";
  } else if (NFC_TEAMS.includes(teamCode)) {
    return "/nfl/nfl-nfc-National_Football_Conference_logo.png";
  }
  return "/nfl/fallback-logo.png";
};

// Helper to return a background class based on whether the guessed team's conference matches the daily team's conference.
const getConferenceBackground = (guessedTeamCode, dailyTeamCode) => {
  // We compare the logos since they differ per conference.
  const guessedConference = getConferenceLogo(guessedTeamCode);
  const dailyConference = getConferenceLogo(dailyTeamCode);
  return guessedConference === dailyConference
    ? "bg-green-500 text-white"
    : "bg-red-500 text-white";
};

const parseHeight = (heightStr) => {
  if (!heightStr) return NaN;
  const parts = heightStr.split("-");
  if (parts.length !== 2) return NaN;
  const feet = parseInt(parts[0], 10);
  const inches = parseInt(parts[1], 10);
  if (isNaN(feet) || isNaN(inches)) return NaN;
  return feet * 12 + inches;
};

const ClassicGameNfl = () => {
  // Use NFL-specific keys in local storage
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [guess, setGuess] = useState("");
  const [dailyPlayer, setDailyPlayer] = useState(null);
  const [yesterdayPlayer, setYesterdayPlayer] = useState(null);
  const [gameWon, setGameWon] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [streak, setStreak] = useState(0);
  const [arrowSrc, setArrowSrc] = useState("/arrow-right.png");
  const [stats, setStats] = useState({
    totalWins: 0,
    totalGames: 0,
    currentStreak: 0,
    maxStreak: 0,
    avgGuesses: 0,
  });
  const [totalGuesses, setTotalGuesses] = useState(0);
  const [yesterdayGuesses, setYesterdayGuesses] = useState(0);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userGuessRank, setUserGuessRank] = useState(null);
  const cardRef = useRef(null);
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";
  const sessionId = localStorage.getItem("session_id");

  // Force a refresh once per session to reset local state
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const lastPlayedDate = localStorage.getItem("nfl_lastPlayedDate");

    if (lastPlayedDate !== today) {
      console.log("New day detected - resetting NFL game state!");

      // 🚨 Clear all `nfl_gameWon_*` and `nfl_guesses_*` keys
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("nfl_gameWon") || key.startsWith("nfl_guesses")) {
          localStorage.removeItem(key);
        }
      });

      // 🚨 Reset main storage keys
      localStorage.setItem("nfl_lastPlayedDate", today);
      localStorage.removeItem("nfl_gameWon");

      // 🚨 Ensure state resets properly
      setGuesses([]);
      setGameWon(false);

      // 🚨 Force a refresh on mobile to avoid caching issues
      if (navigator.userAgent.toLowerCase().includes("mobile")) {
        console.log("Mobile detected - forcing refresh");
        window.location.reload();
      }
    }
  }, []);

  // Fetch today's and yesterday's guess totals (using nfl_ prefix)
  useEffect(() => {
    const fetchGuesses = async () => {
      const today = new Date().toISOString().split("T")[0];
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayStr = yesterday.toISOString().split("T")[0];
      try {
        const todayResponse = await fetch(
          `${API_BASE_URL}/api/nfl/total-guesses`
        );
        if (!todayResponse.ok)
          throw new Error("Failed to fetch today's guesses.");
        const todayData = await todayResponse.json();
        setTotalGuesses(todayData.totalGuesses || 0);

        const yesterdayResponse = await fetch(
          `${API_BASE_URL}/api/nfl/total-guesses-date?date=${yesterdayStr}`
        );
        if (!yesterdayResponse.ok)
          throw new Error("Failed to fetch yesterday's guesses.");
        const yesterdayData = await yesterdayResponse.json();
        setYesterdayGuesses(yesterdayData.correctGuesses || 0);
      } catch (error) {
        console.error("Error fetching guesses:", error);
      }
    };

    fetchGuesses();
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/nfl/total-guesses`);
        if (!response.ok) throw new Error("Failed to fetch total guesses.");
        const data = await response.json();
        setTotalGuesses(data.totalGuesses || 0);
      } catch (error) {
        console.error("Error fetching total guesses:", error);
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [API_BASE_URL]);

  // Calculate and update game stats from localStorage (using nfl_ keys)
  useEffect(() => {
    const calculateStats = () => {
      const savedWins = parseInt(
        localStorage.getItem("nfl_totalWins") || "0",
        10
      );
      const savedGames = parseInt(
        localStorage.getItem("nfl_totalGames") || "0",
        10
      );
      const savedMaxStreak = parseInt(
        localStorage.getItem("nfl_maxStreak") || "0",
        10
      );
      const savedCurrentStreak = parseInt(
        localStorage.getItem("nfl_streak") || "0",
        10
      );
      const savedGuesses = JSON.parse(
        localStorage.getItem("nfl_guessCounts") || "[]"
      );
      const totalGuessCount = savedGuesses.reduce(
        (sum, count) => sum + count,
        0
      );
      const avgGuesses =
        savedGames > 0 ? (totalGuessCount / savedGames).toFixed(2) : 0;
      setStats({
        totalWins: savedWins,
        totalGames: savedGames,
        currentStreak: savedCurrentStreak,
        maxStreak: savedMaxStreak,
        avgGuesses,
      });
    };
    calculateStats();
  }, [gameWon]);

  // Calculate time left until midnight (UTC)
  const calculateTimeLeft = () => {
    const now = DateTime.utc();
    const midnightUTC = now.endOf("day");
    const difference = midnightUTC.diff(now, ["hours", "minutes", "seconds"]);
    return {
      hours: difference.hours,
      minutes: difference.minutes,
      seconds: Math.floor(difference.seconds),
    };
  };

  // Load saved guesses and gameWon state from localStorage (using nfl_ keys)
  useEffect(() => {
    const savedGuesses = JSON.parse(
      localStorage.getItem("nfl_guesses") || "[]"
    );
    setGuesses(savedGuesses);

    const savedGameWon = localStorage.getItem("nfl_gameWon") === "true";
    if (savedGameWon) {
      setGameWon(true);
    }
  }, []);

  // Initialize streak from localStorage (nfl_streak)
  useEffect(() => {
    const savedStreak = parseInt(localStorage.getItem("nfl_streak") || "0", 10);
    setStreak(savedStreak);
  }, []);

  // Update streak when game is won
  useEffect(() => {
    if (gameWon) {
      const today = new Date().toISOString().split("T")[0];
      const lastPlayedDate = localStorage.getItem("nfl_lastPlayedDate");
      let currentStreak = parseInt(
        localStorage.getItem("nfl_streak") || "0",
        10
      );
      if (!lastPlayedDate || lastPlayedDate !== today) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayStr = yesterday.toISOString().split("T")[0];
        currentStreak = lastPlayedDate === yesterdayStr ? currentStreak + 1 : 1;
        localStorage.setItem("nfl_lastPlayedDate", today);
      }
      localStorage.setItem("nfl_streak", currentStreak);
      setStreak(currentStreak);
    }
  }, [gameWon]);

  // Save guesses and gameWon state for the current daily player in localStorage (using nfl_ keys)
  useEffect(() => {
    if (gameWon && dailyPlayer) {
        localStorage.setItem("nfl_gameWon", "true");
    } else {
        localStorage.removeItem("nfl_gameWon"); // 🚀 Ensure reset!
    }
}, [gameWon, dailyPlayer]);

  // Update countdown timer every second
  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Reset game for a new day if needed (using nfl_ keys)
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const lastPlayedDate = localStorage.getItem("nfl_lastPlayedDate");

    if (lastPlayedDate !== today) {
      setGuesses([]);
      setGameWon(false);
      localStorage.setItem("nfl_lastPlayedDate", today);

      if (dailyPlayer) {
        localStorage.removeItem(`nfl_guesses_${dailyPlayer.id}_${sessionId}`);
        localStorage.removeItem(`nfl_gameWon_${dailyPlayer.id}_${sessionId}`);
      }

      // Ensure that nfl_gameWon is also reset globally
      localStorage.removeItem("nfl_gameWon");
    }
  }, [dailyPlayer, sessionId]);

  // Fetch NFL players data
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/nfl/players`);
        if (!response.ok) throw new Error("Failed to fetch players");
        const data = await response.json();
        // Expected NFL player fields: id, number, name, position, height, weight, dob_age, experience, college, team
        setPlayers(data);
      } catch (error) {
        console.error("Error fetching players:", error.message);
      }
    };
    fetchPlayers();
  }, [API_BASE_URL]);

  // Fetch the daily NFL player and yesterday's player
  useEffect(() => {
    const fetchDailyPlayer = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/nfl/daily-player`, {
          headers: { "x-session-id": sessionId },
        });
        if (!response.ok) throw new Error("Failed to fetch daily player");
        const data = await response.json();
        if (!data) {
          console.error("No active daily player found.");
          return;
        }
        setDailyPlayer(data);
        const savedGuesses =
          JSON.parse(
            localStorage.getItem(`nfl_guesses_${data.id}_${sessionId}`)
          ) || [];
        setGuesses(savedGuesses);
        if (
          localStorage.getItem(`nfl_gameWon_${data.id}_${sessionId}`) === "true"
        ) {
          setGameWon(true);
        }
      } catch (error) {
        console.error("Error fetching daily player:", error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchYesterdayPlayer = async () => {
      try {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const formattedDate = yesterday.toISOString().split("T")[0];
        const response = await fetch(
          `${API_BASE_URL}/api/nfl/daily-player-date?date=${formattedDate}`
        );
        if (!response.ok) throw new Error("Failed to fetch yesterday's player");
        const data = await response.json();
        if (!data) {
          console.error("No player found for yesterday.");
          return;
        }
        setYesterdayPlayer(data);
      } catch (error) {
        console.error("Error fetching yesterday's player:", error.message);
      }
    };

    fetchDailyPlayer();
    fetchYesterdayPlayer();
  }, [API_BASE_URL, sessionId]);

  // Handle input change in the guess field
  const handleInputChange = (e) => {
    const input = e.target.value.toLowerCase();
    setGuess(input);
  
    if (input) {
      const filtered = players
        .filter((player) => player.name.toLowerCase().includes(input))
        .slice(0, 20);
      setFilteredPlayers(filtered);
    } else {
      setFilteredPlayers([]);
    }
  };
  

  // When a suggested player is clicked, set the input to the formatted (second part) name.
  const handleSelectPlayer = (player) => {
    setGuess(player.name);
    setFilteredPlayers([]);
  };

  // When submitting a guess, compare using the formatted name.
  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    if (!dailyPlayer) {
      alert("Daily player data is not available yet.");
      return;
    }
    const selectedPlayer = players.find(
      (player) =>
        player.name.toLowerCase() === guess.toLowerCase()
    );
    if (!selectedPlayer) {
      alert("Player not found. Please select a valid player.");
      return;
    }
    // Build feedback object using NFL fields.
    const feedback = {
      name: selectedPlayer.name,
      team: selectedPlayer.team,
      number: selectedPlayer.number,
      position: selectedPlayer.position,
      height: selectedPlayer.height,
      weight: selectedPlayer.weight,
      dob_age: selectedPlayer.dob_age,
      experience: selectedPlayer.experience,
      college: selectedPlayer.college,
      visibleCategories: [],
    };

    setGuesses((prev) => [...prev, feedback]);

    // Reveal clues gradually (including the new conference category)
    const revealCategories = [
      "team",
      "conference",
      "number",
      "position",
      "height",
      "weight",
      "dob_age",
      "experience",
      "college",
    ];
    revealCategories.forEach((category, index) => {
      setTimeout(() => {
        setGuesses((prev) =>
          prev.map((g, guessIndex) =>
            guessIndex === prev.length - 1
              ? { ...g, visibleCategories: [...g.visibleCategories, category] }
              : g
          )
        );
      }, index * 400);
    });

    // Check if the guessed player is the daily player.
    if (selectedPlayer.name === dailyPlayer.name) {
      setGameWon(true);
      localStorage.setItem("nfl_gameWon", "true");
      await (async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/nfl/increment-correct-guesses`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-session-id": sessionId,
              },
            }
          );
          if (!response.ok)
            throw new Error("Failed to increment correct guesses.");
          const guessResponse = await fetch(
            `${API_BASE_URL}/api/nfl/total-guesses`
          );
          if (!guessResponse.ok)
            throw new Error("Failed to fetch total guesses.");
          const guessData = await guessResponse.json();
          const guessRank = guessData.totalGuesses + 1;
          setUserGuessRank(guessRank);
          localStorage.setItem("nfl_userGuessRank", guessRank);
          console.log(`You were the #${guessRank} to guess correctly!`);
        } catch (error) {
          console.error("Error counting correct guess:", error);
        }
      })();
      const currentWins = parseInt(
        localStorage.getItem("nfl_totalWins") || "0",
        10
      );
      const currentGames = parseInt(
        localStorage.getItem("nfl_totalGames") || "0",
        10
      );
      const guessCounts = JSON.parse(
        localStorage.getItem("nfl_guessCounts") || "[]"
      );
      localStorage.setItem("nfl_totalWins", currentWins + 1);
      localStorage.setItem("nfl_totalGames", currentGames + 1);
      localStorage.setItem("nfl_maxStreak", Math.max(streak, stats.maxStreak));
      guessCounts.push(guesses.length + 1);
      localStorage.setItem("nfl_guessCounts", JSON.stringify(guessCounts));
      setConfettiRunning(true);
      setTimeout(() => setConfettiRunning(false), 7000);
      setTimeout(() => {
        cardRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
    setGuess("");
  };

  // Generate share message for social sharing.
  const generateShareMessage = () => {
    const shareUrl = "www.sportsdle.com"; // Replace with your NFL app URL
    const guessCount = guesses.length;
    return `🏈🎉 I guessed today's NFL player on Sportsdle in ${guessCount} ${
      guessCount === 1 ? "guess" : "guesses"
    }! Can you do it too?\n${shareUrl}`;
  };

  // Copy share message to clipboard.
  const copyToClipboard = () => {
    const shareMessage = generateShareMessage();
    navigator.clipboard
      .writeText(shareMessage)
      .then(() => {
        alert("Copied to clipboard! Share your win with friends 🎉");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  // Load NFL-specific user guess ranking from localStorage on mount.
  useEffect(() => {
    const savedGuessRank = localStorage.getItem("nfl_userGuessRank");
    if (savedGuessRank) {
      setUserGuessRank(parseInt(savedGuessRank, 10));
    }
  }, []);

  // Simulate a progress bar and set a minimum loading time.
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 5;
      });
    }, 100);
    const timeout = setTimeout(() => {
      setLoading(false);
      setDataLoaded(true);
    }, 3000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

    // 1. Retrieve total wins from localStorage
    const wins = parseInt(localStorage.getItem("nfl_totalWins") || "0", 10);

  if (loading) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-black text-white">
        <div
          className="radial-progress text-primary"
          style={{ "--value": progress }}
          role="progressbar"
        >
          {progress}%
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 bg-cover bg-center"
      style={{ backgroundImage: "url('/nfl_background.jpg')" }}
    >
      {/* Overlay with a lower z-index */}
      <div className="absolute inset-0 bg-black bg-opacity-60 pointer-events-none z-0"></div>

      {/* Header with a higher z-index */}
      <div className="relative z-10">
        <Header />
      </div>

      {confettiRunning && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}
      <div className="relative max-w-5xl mx-auto h-screen overflow-y-auto rounded-lg px-4 pt-2 pb-72 mt-5 mb-5">
        {/* Countdown and Clues */}
        <div className="flex flex-col md:flex-row justify-center items-start gap-4 mb-4 sm:w-8/12 mx-auto">
          <div className="flex-1 w-full bg-black bg-opacity-70 rounded-lg shadow-md">
            <div className="relative text-white p-4 text-center">
              <h1 className="font-bold text-xl mb-2">
                NFL Classic Guessing Game
              </h1>
              <p className="text-base mb-2">Time left until the next player:</p>
              <span className="countdown font-mono text-2xl">
                <span style={{ "--value": timeLeft.hours }}></span>h
                <span style={{ "--value": timeLeft.minutes }}></span>m
                <span style={{ "--value": timeLeft.seconds }}></span>s
              </span>
              <div className="mt-4 flex justify-around items-start gap-4">
                <div className="text-center">
                  {guesses.length < 4 ? (
                    <p className="text-md text-gray-400">
                      Clue in {4 - guesses.length} guess(es)
                    </p>
                  ) : (
                    <p className="text-md font-bold">
                      Team: {dailyPlayer.team}
                    </p>
                  )}
                </div>
                <div className="text-center">
                  {guesses.length < 6 ? (
                    <p className="text-md text-gray-400">
                      Clue in {6 - guesses.length} guess(es)
                    </p>
                  ) : (
                    <p className="text-md font-bold">
                      Jersey: {dailyPlayer.number}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Input and Filtered Players */}
        <div className="w-full sm:w-5/6 mb-4 mx-auto flex flex-col space-y-4">
          {/* Top Row: Info Buttons and Medal */}
          <div className="flex items-center justify-center gap-4">
            {/* Info Buttons Container */}
            <div className="flex items-center justify-between bg-neutral bg-opacity-95 rounded-md shadow-md px-3 py-2 sm:w-3/12 w-6/12">
              {/* How to Play Button */}
              <div className="tooltip" data-tip="How to play">
                <div className="flex items-center justify-center">
                  <HowToPlay />
                </div>
              </div>

              {/* Divider */}
              <div className="h-8 w-[2px] bg-gray-500 mx-2"></div>

              {/* Updates Button with Badge */}
              <div className="tooltip" data-tip="Updates">
                <div className="relative flex items-center justify-center">
                  <Updates />
                  <div className="absolute top-0 right-0 transform translate-x-2 -translate-y-4">
                    <span className="badge badge-primary badge-md">!</span>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <div className="h-8 w-[2px] bg-gray-500 mx-2"></div>

              {/* About Button */}
              <div className="tooltip" data-tip="About">
                <div className="flex items-center justify-center">
                  <About />
                </div>
              </div>
            </div>

            {/* Medal / Trophy Component as a sibling */}
            <Medal wins={wins} />
          </div>

          <div className="relative w-full sm:w-5/6 mx-auto">
            <form onSubmit={handleSubmit} className="relative">
              <input
                type="text"
                value={guess}
                onChange={handleInputChange}
                placeholder={
                  gameWon
                    ? "You found the player! Wait for the next one."
                    : "Start typing a player's name"
                }
                className={`w-full h-12 p-3 pr-12 rounded-lg text-lg ${
                  gameWon
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-white text-black"
                }`}
                disabled={gameWon}
              />
              {!gameWon && (
                <button
                  type="submit"
                  onMouseEnter={() => setArrowSrc("/arrow-right-blue.png")}
                  onMouseLeave={() => setArrowSrc("/arrow-right.png")}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <img src={arrowSrc} alt="Submit" className="w-8 h-8" />
                </button>
              )}
            </form>
            {filteredPlayers.length > 0 && (
              <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-lg mt-2 max-h-40 overflow-y-auto z-20">
                {filteredPlayers.map((player) => (
                  <li
                    key={player.id}
                    onClick={() => handleSelectPlayer(player)}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center text-black"
                  >
                    <span>{player.name}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="text-center">
          <h3 className="text-sm font-bold">
            <span className="text-yellow-400">{totalGuesses}</span> people
            already found today's player!
          </h3>
        </div>

        {/* Comparison Grid for Guesses */}
        <div className="overflow-x-auto">
          <div className="space-y-2 w-max mx-auto font-bold">
            {/* Grid Header */}
            <div className="grid grid-cols-10 gap-2 text-white text-center p-1 font-bold text-md whitespace-nowrap">
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Player
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Team
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Conference
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Jersey
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Position
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Height
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Weight
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Age
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Experience
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                College
              </div>
            </div>
            {/* Grid Rows */}
            {[...guesses].reverse().map((g, rowIndex) => (
              <div
                key={rowIndex}
                className="grid grid-cols-10 gap-2 bg-gray-900 bg-opacity-60 p-2 rounded-lg text-md whitespace-nowrap overflow-visible"
              >
                <div className="flex justify-center items-center text-center w-20 h-20 font-bold rounded-lg px-1 whitespace-normal break-words overflow-hidden">
                  {g.name}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("team")
                      ? getBackgroundColor(g.team, dailyPlayer.team, "string")
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("team") ? (
                    <img
                      src={`/nfl/${getTeamLogoFilename(g.team)}`}
                      alt={g.team}
                      className="w-12 h-12 object-contain"
                    />
                  ) : (
                    "?"
                  )}
                </div>
                {/* Conference */}
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("conference")
                      ? getConferenceBackground(g.team, dailyPlayer.team)
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("conference") ? (
                    <img
                      src={getConferenceLogo(g.team)}
                      alt="Conference"
                      className="w-12 h-12 object-contain"
                    />
                  ) : (
                    "?"
                  )}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("number")
                      ? getBackgroundColor(
                          g.number,
                          dailyPlayer.number,
                          "number"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("number")
                    ? renderArrowPositioned(
                        g.number,
                        dailyPlayer.number,
                        "number"
                      )
                    : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("position")
                      ? getBackgroundColor(
                          g.position,
                          dailyPlayer.position,
                          "string"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("position") ? g.position : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("height")
                      ? getBackgroundColor(
                          g.height,
                          dailyPlayer.height,
                          "height"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("height") ? g.height : "?"}
                </div>

                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("weight")
                      ? getBackgroundColor(
                          g.weight,
                          dailyPlayer.weight,
                          "number"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("weight")
                    ? renderArrowPositioned(
                        g.weight,
                        dailyPlayer.weight,
                        "number"
                      )
                    : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("dob_age")
                      ? getBackgroundColor(
                          extractAge(g.dob_age),
                          extractAge(dailyPlayer.dob_age),
                          "number"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("dob_age")
                    ? renderArrowPositioned(
                        extractAge(g.dob_age),
                        extractAge(dailyPlayer.dob_age),
                        "number"
                      )
                    : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg ${
                    g.visibleCategories.includes("experience")
                      ? getBackgroundColor(
                          g.experience,
                          dailyPlayer.experience,
                          "number"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("experience")
                    ? renderArrowPositioned(
                        g.experience,
                        dailyPlayer.experience,
                        "number"
                      )
                    : "?"}
                </div>
                <div
                  className={`flex justify-center items-center text-center w-20 h-20 rounded-lg text-sm px-4 whitespace-normal break-words overflow-hidden ${
                    g.visibleCategories.includes("college")
                      ? getBackgroundColor(
                          g.college,
                          dailyPlayer.college,
                          "string"
                        )
                      : "bg-gray-700 text-white"
                  }`}
                >
                  {g.visibleCategories.includes("college") ? g.college : "?"}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="block md:hidden text-center text-white mt-2">
          <p className="text-sm">
            Swipe or scroll to the right to view more details!
          </p>
        </div>

        <div className="text-center my-10">
          <h3 className="text-sm font-bold">
            <span className="text-yellow-400">{yesterdayGuesses}</span> people
            found yesterday's player{" "}
            {yesterdayPlayer?.name && (
              <span className="text-yellow-500">
                ({yesterdayPlayer.name})
              </span>
            )}
            !
          </h3>
        </div>

        {gameWon && (
          <div
            ref={cardRef}
            className="text-white text-center p-5 rounded-lg mt-8 max-w-md mx-auto"
            style={{
              background: "linear-gradient(135deg, #1b5e20, #388e3c)",
              border: "2px solid #4caf50",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h2 className="text-3xl font-bold mb-4">Congratulations!</h2>
            <div className="flex flex-col items-center">
              <p className="text-lg">You found</p>
              <div className="flex items-center mt-2">
                <span className="text-2xl font-bold ml-3">
                  {dailyPlayer?.name}
                </span>
              </div>
            </div>
            <p className="text-lg mt-4">
              You were the{" "}
              <span className="font-bold text-yellow-300">
                #{userGuessRank ? userGuessRank : "?"}
              </span>{" "}
              to guess correctly!
            </p>
            <p className="text-lg mt-2">
              This player is for the day:{" "}
              <strong>
                {DateTime.fromISO(dailyPlayer?.date, { zone: "utc" })
                  .setZone("America/New_York")
                  .toFormat("yyyy-MM-dd")}
              </strong>
            </p>
            <div className="mt-4 flex flex-col items-center w-full space-y-2">
              <button
                onClick={copyToClipboard}
                className="flex items-center justify-center w-full bg-gray-600 text-white font-semibold py-3 rounded-lg hover:bg-gray-700 transition"
              >
                <img
                  src="/logos/clipboard.png"
                  alt="Copy"
                  className="w-5 h-5 mr-2"
                />
                Copy Result
              </button>
              <a
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  generateShareMessage()
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full bg-[#1DA1F2] text-white font-semibold py-3 rounded-lg hover:bg-[#0d8bcd] transition"
              >
                <img
                  src="/logos/twitter.png"
                  alt="Twitter"
                  className="w-5 h-5 mr-2"
                />
                Share on Twitter
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  "https://yournflapp.com"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full bg-[#1877F2] text-white font-semibold py-3 rounded-lg hover:bg-[#145dbf] transition"
              >
                <img
                  src="/logos/facebook.png"
                  alt="Facebook"
                  className="w-5 h-5 mr-2"
                />
                Share on Facebook
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassicGameNfl;
