import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ClassicGameNHL from './pages/Nhl/ClassicGame';
import HomePage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UnlimitedModeNHL from './pages/Nhl/UnlimitedMode';
import ClassicGameNFL from './pages/Nfl/ClassicGameNfl';
import ClassicGameNBA from './pages/Nba/ClassicGameNba';
import RandomPlayerNHL from './pages/Nhl/RandomPlayerNhl';

function App() {
    return (
        <Router>
            <div className="absolute inset-0 bg-black bg-opacity-60"></div>

            <div className="relative z-10 flex flex-col items-center justify-center min-h-screen text-white">
                <Routes>
                    <Route path="/" element={<HomePage />} />

                    {/* NHL Routes */}
                    <Route path="/nhl/daily-guessing-game" element={<ClassicGameNHL />} />
                    <Route path="/nhl/unlimited-guessing-game" element={<UnlimitedModeNHL />} />
                    <Route path="/nhl/random-player-generator" element={<RandomPlayerNHL />} />

                    {/* NFL Routes */}
                    <Route path="/nfl/daily-guessing-game" element={<ClassicGameNFL />} />

                    {/* NBA Routes */}
                    <Route path="/nba/daily-guessing-game" element={<ClassicGameNBA />} />

                    {/* Miscellaneous */}
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;