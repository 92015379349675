// Adjust these filenames to match the actual files in your /public/nfl directory.
const TEAM_LOGOS = {
    BUF: "nfl-buffalo-bills-team-logo.png",
    MIA: "nfl-miami-dolphins-team-logo.png",
    NE:  "nfl-new-england-patriots-team-logo.png",
    NYJ: "New-York-Jets-wordmark-logo-2024.png",
    BAL: "nfl-baltimore-ravens-team-logo.png",
    CIN: "nfl-cincinnati-bengals-team-logo.png",
    CLE: "nfl-cleveland-browns-team-logo.png",
    PIT: "nfl-pittsburgh-steelers-team-logo.png",
    HOU: "nfl-houston-texans-team-logo.png",
    IND: "nfl-indianapolis-colts-team-logo.png",
    JAC: "nfl-jacksonville-jaguars-team-logo.png",
    TEN: "nfl-tennessee-titans-team-logo.png",
    DEN: "nfl-denver-broncos-team-logo.png",
    KC:  "nfl-kansas-city-chiefs-team-logo.png",
    LV:  "nfl-oakland-raiders-team-logo.png",
    LAC: "nfl-los-angeles-chargers-team-logo.png",
    DAL: "nfl-dallas-cowboys-team-logo.png",
    NYG: "nfl-new-york-giants-team-logo.png",
    PHI: "nfl-philadelphia-eagles-team-logo.png",
    WAS: "washington-commanders-wordmark-logo.png",
    CHI: "nfl-chicago-bears-team-logo.png",
    DET: "nfl-detroit-lions-team-logo.png",
    GB:  "nfl-green-bay-packers-team-logo.png",
    MIN: "nfl-minnesota-vikings-team-logo.png",
    ATL: "nfl-atlanta-falcons-team-logo.png",
    CAR: "nfl-carolina-panthers-team-logo.png",
    NO:  "nfl-new-orleans-saints-team-logo.png",
    TB:  "nfl-tampa-bay-buccaneers-2020-logo.png",
    ARI: "nfl-arizona-cardinals-team-logo.png",
    LAR: "los-angeles-rams-2020-logo.png",
    SF:  "nfl-san-francisco-49ers-team-logo.png",
    SEA: "nfl-seattle-seahawks-team-logo.png",
    // ...and so on for any remaining codes...
  };
  
  // Helper function to return the correct filename or a fallback if not found
  export function getTeamLogoFilename(code) {
    return TEAM_LOGOS[code] || "fallback-logo.png"; 
  }  