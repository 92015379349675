import React, { useState, useEffect } from "react";
import Header from "../../components/GameBoard/Header";

const RandomPlayerNHL = () => {
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";

  const fetchRandomPlayer = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/api/random-player`);
      if (!response.ok) throw new Error("Failed to fetch random player");
      const data = await response.json();
      setPlayer(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRandomPlayer();
  }, []);

  const formattedBirthDate = player?.birth_date
    ? new Date(player.birth_date).toLocaleDateString()
    : "N/A";

  return (
    <div
      className="fixed inset-0 bg-cover bg-center overflow-hidden" // Changed overflow-auto to overflow-hidden
      style={{ backgroundImage: "url('/background.jpg')" }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-60 pointer-events-none"></div>

      <div className="relative z-10">
        <Header />
        <div className="container mx-auto p-4 flex flex-col items-center">
          <h1 className="text-3xl font-bold text-center mb-6 text-yellow-400">
            Random NHL Player
          </h1>

          <div className="card bg-base-100 shadow-xl max-w-3xl w-full overflow-y-auto max-h-[70vh] lg:flex lg:flex-row">
            {loading ? (
              <div className="flex items-center justify-center w-full py-20">
                <p className="text-xl">Loading...</p>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center w-full py-20">
                <p className="text-xl text-red-500">{error}</p>
              </div>
            ) : (
              <>
                {player.headshot_url && (
                  <figure className="flex-shrink-0 lg:w-1/3 w-full">
                    <img
                      src={player.headshot_url}
                      alt={player.full_name}
                      className="object-cover h-full w-full"
                    />
                  </figure>
                )}
                <div className="card-body text-black overflow-auto">
                  <h2 className="card-title text-3xl">{player.full_name}</h2>
                  <div className="flex flex-col gap-2 text-base">
                    <p>
                      <strong>Team:</strong>{" "}
                      {player.current_team_full_name ||
                        player.current_team_common_name ||
                        "N/A"}
                      {player.current_team_logo && (
                        <img
                          src={player.current_team_logo}
                          alt={player.current_team_common_name}
                          className="inline-block w-12 h-auto ml-2"
                        />
                      )}
                    </p>
                    <p>
                      <strong>Position:</strong> {player.position || "N/A"} |{" "}
                      <strong>Jersey:</strong> {player.sweater_number || "N/A"}{" "}
                      | <strong>Height:</strong>{" "}
                      {player.height_inches
                        ? `${player.height_inches} in`
                        : "N/A"}{" "}
                      | <strong>Weight:</strong>{" "}
                      {player.weight_pounds
                        ? `${player.weight_pounds} lbs`
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Birth:</strong> {formattedBirthDate}{" "}
                      {player.birth_city && `- ${player.birth_city}`}{" "}
                      {player.birth_state_province &&
                        `, ${player.birth_state_province}`}{" "}
                      {player.birth_country && `, ${player.birth_country}`}
                    </p>
                  </div>

                  <div className="divider"></div>
                  <h3 className="text-xl font-bold">Career Statistics</h3>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <p>
                      <strong>Regular Season Games:</strong>{" "}
                      {player.career_regular_season_games || "N/A"}
                    </p>
                    <p>
                      <strong>Goals:</strong>{" "}
                      {player.career_regular_season_goals || "N/A"}
                    </p>
                    <p>
                      <strong>Assists:</strong>{" "}
                      {player.career_regular_season_assists || "N/A"}
                    </p>
                    <p>
                      <strong>Points:</strong>{" "}
                      {player.career_regular_season_points || "N/A"}
                    </p>
                    <p>
                      <strong>+/-:</strong>{" "}
                      {player.career_regular_season_plus_minus || "N/A"}
                    </p>
                    <p>
                      <strong>Playoffs Games:</strong>{" "}
                      {player.career_playoffs_games || "N/A"}
                    </p>
                    <p>
                      <strong>Playoffs Goals:</strong>{" "}
                      {player.career_playoffs_goals || "N/A"}
                    </p>
                    <p>
                      <strong>Playoffs Assists:</strong>{" "}
                      {player.career_playoffs_assists || "N/A"}
                    </p>
                    <p>
                      <strong>Playoffs Points:</strong>{" "}
                      {player.career_playoffs_points || "N/A"}
                    </p>
                  </div>

                  <div className="card-actions justify-end mt-4">
                    <button
                      className="btn btn-primary"
                      onClick={fetchRandomPlayer}
                    >
                      Show Another Player
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomPlayerNHL;