import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/GameBoard/Header"; // Using the Sportsdle header
// Session helper
const fetchSessionId = async () => {
  let sessionId = localStorage.getItem("session_id");
  if (!sessionId) {
    try {
      const response = await fetch("/api/session-id");
      if (response.ok) {
        sessionId = response.headers.get("x-session-id");
        localStorage.setItem("session_id", sessionId);
      }
    } catch (error) {
      console.error("Error fetching session ID:", error);
    }
  }
  return sessionId;
};

const GameModeCard = ({ title, description, link, image, badgeText }) => {
  return (
    <Link
      to={link}
      className="relative flex items-end justify-center bg-cover bg-center rounded-lg shadow-lg overflow-hidden h-80 w-full max-w-lg mx-auto transition-transform transform hover:scale-105"
      style={{ backgroundImage: `url(${image})` }}
    >
      {badgeText && (
        <div className="absolute top-4 right-4 badge badge-info text-neutral">
          {badgeText}
        </div>
      )}
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
      <div className="relative z-10 p-6 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">{title}</h2>
        <p className="text-md text-gray-200">{description}</p>
      </div>
    </Link>
  );
};

const HomePage = () => {
  useEffect(() => {
    const initializeSession = async () => {
      await fetchSessionId();
    };
    initializeSession();
  }, []);

  return (
    <div className="fixed inset-0 bg-slate-950">
      <Header/>
      {/* Use your Sportsdle header */}
      <div className="relative max-w-4xl mx-auto h-screen overflow-y-auto rounded-lg px-8 pt-2 pb-72 mt-5">
        <div className="text-center mb-12">
          <h1 className="text-4xl sm:text-5xl font-extrabold mb-4">
            Welcome to <span className="text-yellow-400">Sportsdle</span> – The Ultimate Sports Wordle Game!
          </h1>
          <p className="text-xl sm:text-2xl font-medium sm:mx-20">
            Test your knowledge on both hockey and football with daily Wordle-style challenges.
          </p>
        </div>

        <h2 className="text-4xl font-bold text-center mb-8">Game Modes</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <GameModeCard
            title="NHL Classic"
            description="Guess today's NHL player using hints and stats in this Wordle-style game."
            link="/nhl/daily-guessing-game"
            image="/nhl-classic-bg.jpg"
            badgeText="Updated"
          />
          <GameModeCard
            title="NFL Classic"
            description="Guess today's NFL player using hints and stats in a Wordle-style format."
            link="/nfl/daily-guessing-game"
            image="/nfl-classic-bg.png"
            badgeText="Updated"
          />
          <GameModeCard
            title="NBA Classic"
            description="Guess today's NBA player using hints and stats in a Wordle-style format."
            link="/nba/daily-guessing-game"
            image="/nba-classic-bg.png"
            badgeText="New"
          />
        </div>

        <div className="mt-16 max-w-4xl mx-auto bg-black bg-opacity-50 p-8 rounded-lg">
          <h2 className="text-4xl font-bold text-center mb-6">
            What is Sportsdle?
          </h2>
          <p className="text-xl mb-4">
            Sportsdle is your daily sports trivia Wordle challenge—where you can test your skills on both the NHL and NFL. Whether you’re a hockey fanatic or a football buff, each day brings a new challenge and hints to guide you.
          </p>
          <p className="text-xl mb-4">
            Dive into the classic game modes for each league and see if you can guess the mystery player of the day. This Wordle-inspired game will challenge your sports knowledge. Good luck and have fun!
          </p>
        </div>

        <div className="mt-12 text-center">
          <p className="text-xl">
          Explore the official{" "}
            <a
              href="https://www.nhl.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-400 hover:underline"
            >
              NHL website
            </a>{" "}
            and{" "}
            <a
              href="https://www.nfl.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-400 hover:underline"
            >
              NFL website
            </a>{" "}
            for the latest news and stats.
          </p>
          <p className="text-xl mt-4">
            <Link to="/privacy" className="text-yellow-400 hover:underline">
              Read our Privacy Policy
            </Link>
          </p>
        </div>
        <p className="text-center mt-10 text-xl">
          © 2025 Sportsdle. All rights reserved.
        </p>
        <div className="text-center mt-5">
          <a
            href="https://ko-fi.com/meljdev"
            target="_blank"
            rel="noopener noreferrer"
            className="text-yellow-400 font-bold hover:underline text-xl"
          >
            ☕ Support Us on Ko-fi!
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;