import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate(); // Hook to navigate back

  return (
    <div className="fixed inset-0 text-gray-800 overflow-y-auto bg-slate-950">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)} // Go back to the previous page
        className="absolute top-4 left-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded shadow-md"
      >
        ← Back
      </button>

      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg my-20 mx-5">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <p className="mb-4">
          Welcome to Sportsdle! Your privacy is important to us, and this Privacy Policy outlines how we collect, use, and protect your information when you use our services.
        </p>

        <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
        <p className="mb-4">
          When you interact with sportsdle, we may collect the following types of information:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Cookies: We use cookies to improve user experience and gather anonymous usage statistics.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
        <p className="mb-4">
          The information we collect is used for the following purposes:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Improving our website and services.</li>
          <li>Responding to inquiries or requests.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">3. Sharing Your Information</h2>
        <p className="mb-4">
          Sportsdle does not sell, rent, or share your personal information with third parties except in the following cases:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>When required by law or government authorities.</li>
          <li>To protect the safety and rights of Sportsdle and its users.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">4. Cookies and Tracking</h2>
        <p className="mb-4">
          Our website uses cookies to store your preferences and analyze site usage. You can disable cookies through your browser settings, but some features may not function properly.
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Security</h2>
        <p className="mb-4">
          We implement industry-standard security measures to protect your information. However, no method of transmission over the internet is completely secure, so we cannot guarantee absolute security.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Links to External Sites</h2>
        <p className="mb-4">
          NHLDle may contain links to external websites. We are not responsible for the privacy practices or content of these external sites.
        </p>

        <h2 className="text-2xl font-semibold mb-4">7. Changes to This Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated revision date. Please check back periodically.
        </p>

        <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy or our practices, please contact us at:
        </p>
        <p>
          <strong>Email:</strong> meljcontact@gmail.com <br />
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;